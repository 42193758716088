var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tPhysicalStandingBook" },
    [
      _c(
        "Row",
        {
          staticStyle: { height: "100%", padding: "10px" },
          attrs: { gutter: 20 },
        },
        [
          _c(
            "Col",
            { attrs: { span: "4" } },
            [
              _c(
                "Card",
                { staticStyle: { height: "100%" } },
                [
                  _c("Input", {
                    attrs: {
                      suffix: "ios-search",
                      placeholder: "请输入关键字",
                      clearable: "",
                    },
                    on: { input: _vm.inputSearchChange },
                  }),
                  _vm.unitList.length > 0
                    ? _c(
                        "Scroll",
                        { staticClass: "scroll-sty" },
                        [
                          _c("Tree", {
                            ref: "orgTree",
                            attrs: { data: _vm.unitList },
                            on: { "on-select-change": _vm.unitChange },
                          }),
                        ],
                        1
                      )
                    : _c("div", { staticClass: "empty" }, [_vm._v("暂无数据")]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Col",
            { attrs: { span: "20" } },
            [
              _c(
                "Card",
                { ref: "colHeight", staticStyle: { height: "100%" } },
                [
                  _c(
                    "Row",
                    { attrs: { gutter: 10 } },
                    [
                      _c(
                        "Col",
                        { attrs: { span: "24" } },
                        [
                          _c(
                            "Form",
                            {
                              ref: "queryForm",
                              attrs: { "label-width": 80, inline: "" },
                            },
                            [
                              _c(
                                "FormItem",
                                { attrs: { label: "体检编号" } },
                                [
                                  _c("Input", {
                                    staticStyle: { width: "160px" },
                                    attrs: {
                                      placeholder: "请输入体检编号",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.queryFrom.testNum,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.queryFrom, "testNum", $$v)
                                      },
                                      expression: "queryFrom.testNum",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "FormItem",
                                { attrs: { label: "检查种类" } },
                                [
                                  _c(
                                    "Select",
                                    {
                                      staticStyle: { width: "160px" },
                                      attrs: {
                                        transfer: "",
                                        "label-in-value": "",
                                      },
                                      model: {
                                        value: _vm.queryFrom.workStateCode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryFrom,
                                            "workStateCode",
                                            $$v
                                          )
                                        },
                                        expression: "queryFrom.workStateCode",
                                      },
                                    },
                                    _vm._l(
                                      _vm.workStateCodeArr,
                                      function (item, index) {
                                        return _c(
                                          "Option",
                                          {
                                            key: item.id,
                                            attrs: { value: item.value },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.title) + " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "FormItem",
                                { attrs: { label: "姓名" } },
                                [
                                  _c("Input", {
                                    staticStyle: { width: "160px" },
                                    attrs: {
                                      placeholder: "请输入姓名",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.queryFrom.personName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryFrom,
                                          "personName",
                                          $$v
                                        )
                                      },
                                      expression: "queryFrom.personName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "FormItem",
                                { attrs: { label: "身份证号" } },
                                [
                                  _c("Input", {
                                    staticStyle: { width: "160px" },
                                    attrs: {
                                      placeholder: "请输入身份证号",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.queryFrom.idCard,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.queryFrom, "idCard", $$v)
                                      },
                                      expression: "queryFrom.idCard",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.drop
                                ? _c(
                                    "FormItem",
                                    { attrs: { label: "危害因素" } },
                                    [
                                      _c("TreeSelect", {
                                        directives: [
                                          {
                                            name: "width",
                                            rawName: "v-width",
                                            value: 170,
                                            expression: "170",
                                          },
                                        ],
                                        ref: "hazardFactors",
                                        attrs: {
                                          data: _vm.hazardFactorsPriority,
                                          clearable: "",
                                        },
                                        on: {
                                          "on-change": _vm.hazardFactorsChange,
                                        },
                                        model: {
                                          value: _vm.queryFrom.hazardFactors,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryFrom,
                                              "hazardFactors",
                                              $$v
                                            )
                                          },
                                          expression: "queryFrom.hazardFactors",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.drop
                                ? _c(
                                    "FormItem",
                                    { attrs: { label: "状态" } },
                                    [
                                      _c(
                                        "Select",
                                        {
                                          staticStyle: { width: "160px" },
                                          attrs: { transfer: "" },
                                          model: {
                                            value: _vm.queryFrom.isPass,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.queryFrom,
                                                "isPass",
                                                $$v
                                              )
                                            },
                                            expression: "queryFrom.isPass",
                                          },
                                        },
                                        [
                                          _c(
                                            "Option",
                                            { attrs: { value: "89" } },
                                            [_vm._v("全部")]
                                          ),
                                          _c(
                                            "Option",
                                            { attrs: { value: "88" } },
                                            [_vm._v("已总检")]
                                          ),
                                          _c(
                                            "Option",
                                            { attrs: { value: "87" } },
                                            [_vm._v("待总检")]
                                          ),
                                          _c(
                                            "Option",
                                            { attrs: { value: "2" } },
                                            [_vm._v("在体检")]
                                          ),
                                          _c(
                                            "Option",
                                            { attrs: { value: "1" } },
                                            [_vm._v("未登记")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.drop
                                ? _c(
                                    "FormItem",
                                    { attrs: { label: "性别" } },
                                    [
                                      _c(
                                        "Select",
                                        {
                                          staticStyle: { width: "160px" },
                                          attrs: {
                                            transfer: "",
                                            clearable: "",
                                          },
                                          model: {
                                            value: _vm.queryFrom.sex,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.queryFrom,
                                                "sex",
                                                $$v
                                              )
                                            },
                                            expression: "queryFrom.sex",
                                          },
                                        },
                                        [
                                          _c(
                                            "Option",
                                            { attrs: { value: "男" } },
                                            [_vm._v("男")]
                                          ),
                                          _c(
                                            "Option",
                                            { attrs: { value: "女" } },
                                            [_vm._v("女")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.drop
                                ? _c(
                                    "FormItem",
                                    { attrs: { label: "体检日期" } },
                                    [
                                      _c("DatePicker", {
                                        staticStyle: { width: "160px" },
                                        attrs: {
                                          transfer: "",
                                          clearable: false,
                                          placeholder: "请选择",
                                          type: "daterange",
                                        },
                                        on: {
                                          "on-change": _vm.registDateChange,
                                        },
                                        model: {
                                          value: _vm.selectDate,
                                          callback: function ($$v) {
                                            _vm.selectDate = $$v
                                          },
                                          expression: "selectDate",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.drop
                                ? _c(
                                    "FormItem",
                                    { attrs: { label: "体检类型" } },
                                    [
                                      _c(
                                        "Select",
                                        {
                                          staticStyle: { width: "160px" },
                                          attrs: { placeholder: "请选择" },
                                          model: {
                                            value: _vm.queryFrom.physicalType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.queryFrom,
                                                "physicalType",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "queryFrom.physicalType",
                                          },
                                        },
                                        _vm._l(
                                          _vm.inspectTypePriority,
                                          function (item, i) {
                                            return _c(
                                              "Option",
                                              {
                                                key: item.id,
                                                attrs: { value: item.value },
                                              },
                                              [_vm._v(_vm._s(item.title) + " ")]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.drop
                                ? _c(
                                    "FormItem",
                                    { attrs: { label: "是否复查" } },
                                    [
                                      _c(
                                        "Select",
                                        {
                                          staticStyle: { width: "160px" },
                                          model: {
                                            value: _vm.queryFrom.isRecheck,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.queryFrom,
                                                "isRecheck",
                                                $$v
                                              )
                                            },
                                            expression: "queryFrom.isRecheck",
                                          },
                                        },
                                        [
                                          _c(
                                            "Option",
                                            { key: 1, attrs: { value: 1 } },
                                            [_vm._v("是")]
                                          ),
                                          _c(
                                            "Option",
                                            { key: 0, attrs: { value: 0 } },
                                            [_vm._v("否")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "FormItem",
                                {
                                  staticClass: "br",
                                  attrs: { "label-width": 14 },
                                },
                                [
                                  _c(
                                    "Button",
                                    {
                                      attrs: { type: "primary" },
                                      on: {
                                        click:
                                          _vm.getNoCheckProjectPersonSearch,
                                      },
                                    },
                                    [_vm._v("查询")]
                                  ),
                                  _c(
                                    "Dropdown",
                                    { on: { "on-click": _vm.handleDropdown } },
                                    [
                                      _c(
                                        "Button",
                                        [
                                          _vm._v(" 更多操作 "),
                                          _c("Icon", {
                                            attrs: {
                                              type: "md-arrow-dropdown",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "DropdownMenu",
                                        {
                                          attrs: { slot: "list" },
                                          slot: "list",
                                        },
                                        [
                                          _c(
                                            "DropdownItem",
                                            { attrs: { name: "updateAll" } },
                                            [
                                              _c("Icon", {
                                                attrs: {
                                                  type: "ios-checkmark-circle",
                                                },
                                              }),
                                              _vm._v(" 批量网报确认 "),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "DropdownItem",
                                            { attrs: { name: "export" } },
                                            [
                                              _c("Icon", {
                                                attrs: {
                                                  type: "md-arrow-down",
                                                },
                                              }),
                                              _vm._v(" 导出勾选数据 "),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "DropdownItem",
                                            { attrs: { name: "handleReset" } },
                                            [
                                              _c("Icon", {
                                                attrs: { type: "md-sync" },
                                              }),
                                              _vm._v(" 重置 "),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "drop-down",
                                      on: { click: _vm.dropDown },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.dropDownContent) + " "
                                      ),
                                      _c("Icon", {
                                        attrs: { type: _vm.dropDownIcon },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "Row",
                    { attrs: { justify: "end" } },
                    [
                      _c("Table", {
                        ref: "table",
                        staticStyle: { width: "100%" },
                        attrs: {
                          loading: _vm.maleLoading,
                          border: "",
                          columns: _vm.columns,
                          sortable: "custom",
                          data: _vm.tableData,
                          "max-height": _vm.height,
                        },
                        on: { "on-selection-change": _vm.showSelect },
                        scopedSlots: _vm._u([
                          {
                            key: "action",
                            fn: function ({ row, index }) {
                              return [
                                row.is_pass == 3 ||
                                row.isPass == 3 ||
                                row.is_pass == 4 ||
                                row.isPass == 4 ||
                                row.is_pass == 5 ||
                                row.isPass == 5
                                  ? _c(
                                      "Button",
                                      {
                                        staticStyle: { "margin-right": "5px" },
                                        attrs: {
                                          type: "primary",
                                          size: "small",
                                          icon: "ios-print-outline",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.prints(row, index)
                                          },
                                        },
                                      },
                                      [_vm._v("预览 ")]
                                    )
                                  : _vm._e(),
                                _c(
                                  "Button",
                                  {
                                    staticStyle: { "margin-right": "5px" },
                                    attrs: { type: "primary", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.confirmation(row, index)
                                      },
                                    },
                                  },
                                  [_vm._v("网报确认 ")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c(
                        "Row",
                        {
                          staticClass: "page",
                          attrs: { type: "flex", justify: "end" },
                        },
                        [
                          _c("Page", {
                            attrs: {
                              current: _vm.queryFrom.pageNumber,
                              total: _vm.total,
                              "page-size": _vm.queryFrom.pageSize,
                              "page-size-opts": [10, 20, 50, 100, 200, 500],
                              size: "small",
                              "show-total": "",
                              "show-elevator": "",
                              "show-sizer": "",
                              transfer: "",
                            },
                            on: {
                              "on-change": _vm.changePage,
                              "on-page-size-change": _vm.changePageSize,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("reviewPersonalReport", {
        attrs: {
          "type-status": _vm.typeStatus,
          "person-id": _vm.personInfoId,
          physicalType: _vm.physicalType,
        },
        model: {
          value: _vm.previewShow,
          callback: function ($$v) {
            _vm.previewShow = $$v
          },
          expression: "previewShow",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }